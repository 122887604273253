<template>
    <div class="container">
        <legend>稟議カテゴリマスタ</legend>
        <div class="d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="btn-th">編集</th>
                        <th>カテゴリ名</th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in categories" :key="c.id" :class="{'deleted': c.deleted_at}">
                        <td>
                            <button class="btn btn-outline-secondary" @click="edit(c.id)" :disabled="c.deleted_at">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td>
                            {{c.categorie_name}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" v-if="!c.deleted_at" @click="toggle(c)">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success" v-else @click="toggle(c)">
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <categories-editor
            :category_id="category_id"
            @saved="reload"></categories-editor>
    </div>
</template>

<script>
import categoriesEditor from './categoriesEditor.vue'

export default {
    components: {
        categoriesEditor,
    },
    data() {
        return {
            categories: [],
            category_id: null,
            search: '',
            categoryModal: null,
        }
    },
    methods: {
        reload() {
            axios.get("/master/approval/categories/lists").then(res => {
                this.categories = res.data;
            }).catch(err => {Alert.err(err)});;
        },
        edit(id = null) {
            this.category_id = id;
            setTimeout(() => this.categoryModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal({
                title: `稟議カテゴリマスタ${action}`,
                text: `指定のレコードを${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/approval/categories/toggle", {id: item.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => {
                        Alert.err(err.response);
                    });
                }
            });
        },
        // TODO: ソート機能実装
    },
    mounted() {
        this.categoryModal = new bootstrap.Modal(document.getElementById("category-modal"));
        this.reload();
    },
}
</script>
