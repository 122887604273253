<template>
    <div class="container">
        <legend>
            発注先マスタ
        </legend>
        <div class="mb-3 px-5">
            <input type="search" class="form-control form-control-lg" v-model="keywords" @keydown="find($event)" placeholder="検索">
        </div>
        <div class="mb-3 d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <div class="sticky">
            <pagination v-bind="suppliers" @goto="goto($event)" :showTotal="true"></pagination>
        </div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="btn-th">編集</th>
                        <th>支払先名</th>
                        <th>金融機関名</th>
                        <th>支店名</th>
                        <th>口座種別</th>
                        <th>口座番号</th>
                        <th>口座名義</th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="s in suppliers.data" :key="s.id" :class="{'deleted': s.deleted_at}">
                        <td>
                            <button class="btn btn-outline-secondary" @click="edit(s.id)" :disabled="s.deleted_at">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td class="big">
                            {{s.supplier_name}}
                        </td>
                        <td>
                            {{s.bank_name}}
                        </td>
                        <td>
                            {{s.branch_name}}
                        </td>
                        <td>
                            {{s.type_of_account}}
                        </td>
                        <td>
                            {{s.account_number}}
                        </td>
                        <td>
                            {{s.account_holder}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" @click="toggle(s)" v-if="!s.deleted_at">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success" @click="toggle(s)" v-else>
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <suppliers-editor
            :supplier_id="supplier_id"
            @saved="reload"></suppliers-editor>
    </div>
</template>

<script>
import suppliersEditor from './suppliersEditor.vue'
export default {
    components: {
        suppliersEditor,
    },
    data() {
        return {
            suppliers: [],
            page: this.$route.query.page || 1,
            supplier_id: null,
            keywords: "",
            supplierModal: null,
        }
    },
    methods: {
        find(ev) {
            if (!ev.isComposing && ev.code == "Enter") {
                this.page = 1;
                this.reload();
            }
        },
        goto(n) {
            this.page = n;
            this.reload();
        },
        reload() {
            axios.get("/master/suppliers/lists", {params: {
                page: this.page,
                keywords: this.keywords,
            }}).then(res => {
                this.suppliers = res.data;
            }).catch(err => {Alert.err(err)});;
        },
        edit(id = null) {
            this.supplier_id = id;
            setTimeout(() => this.supplierModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal ({
                title: `発注先マスタ${action}`,
                text: `指定のレコードを${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/suppliers/toggle", {id: item.id}).then(res => {
                        this.reload();
                        setTimeout(() => Alert.msg(res.data));
                    }).catch(err => {
                        Alert.err(err.response);
                    });
                }
            });
        },
    },
    watch: {
        keywords(n, o) {
            if (o && o.length > 0 && n.length === 0) {
                this.reload();
            }
        }
    },
    mounted() {
        this.reload();
        this.supplierModal = new bootstrap.Modal(document.getElementById("supplier-modal"));
    },
}
</script>
