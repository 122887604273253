<template>
    <div class="container">
        <legend>分類マスタ</legend>
        <div class="d-flex justify-content-end">
            <button class="btn btn-outline-secondary" @click="edit()">
                <i class="fa-solid fa-plus"></i>
                新規追加
            </button>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th class="btn-th"></th>
                        <th class="btn-th">編集</th>
                        <th>分類詳細</th>
                        <th>分類名</th>
                        <th class="btn-th">削除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="c in ordered" :key="c.id"
                        :class="{'deleted': c.deleted_at}"
                        class="draggable"
                        draggable="true"
                        @dragstart="dragstart(c, $event)"
                        @dragend="dragend"
                        @dragenter="dragenter(c)">
                        <td>
                            <i class="fa-solid fa-grip-vertical"></i>
                        </td>
                        <td>
                            <button class="btn btn-outline-secondary" @click="edit(c.id)" :disabled="c.deleted_at">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                        </td>
                        <td>
                            <router-link class="btn btn-outline-info"
                                :class="{'disabled': c.deleted_at}"
                                 :to="'/master/classification/detail/' + c.id">
                                <i class="fa-solid fa-list"></i>
                                <span class="badge rounded-pill"
                                    :class="{'bg-info': c.details_count > 0,
                                    'bg-secondary': c.details_count == 0}">
                                    {{c.details_count}}
                                </span>
                            </router-link>
                        </td>
                        <td class="big">
                            {{c.classification_name}}
                        </td>
                        <td>
                            <button class="btn btn-outline-danger" @click="toggle(c)" v-if="!c.deleted_at">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button class="btn btn-outline-success" @click="toggle(c)" v-else>
                                <i class="fa-solid fa-rotate-right"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <classification-editor
            :classification_id="classification_id"
            @saved="reload"></classification-editor>
    </div>
</template>

<script>
import classificationEditor from './classificationEditor.vue'

export default {
    components: {
        classificationEditor,
    },
    data() {
        return {
            classifications: [],
            classification_id: null,
            classificationModal: null,
        }
    },

    methods: {
        reload() {
            axios.get("/master/classification/lists").then(res => {
                this.classifications = res.data;
            }).catch(err => {Alert.err(err)});
        },
        edit(id = null) {
            this.classification_id = id;
            setTimeout(() => this.classificationModal.show());
        },
        toggle(item) {
            // 初期値に復帰情報を設定
            let action = "復帰";
            let type = "info";
            let mode = false;
            // 削除の場合、削除情報に変更して表示
            if (!item.deleted_at) {
                action = "削除";
                type   = "error";
                mode = true
            }
            swal ({
                title: `分類マスタ${action}`,
                text: `指定のレコードを${action}します。\nよろしいですか？`,
                icon: type,
                buttons: ["キャンセル" , action],
                dangerMode: mode,
            }).then((confirm) => {
                if (confirm) {
                    axios.post("/master/classification/toggle", {id: item.id}).then(res => {
                        this.reload();
                        Alert.msg(res.data);
                    }).catch(err => { Alert.err(err) });
                }
            });
        },
        // ドラッグStart
        dragstart(item, e) {
            this.drag = {};
            // 掴んでいる情報を設定
            this.drag = item;
        },
        // ドラッグend
        dragend(e) {
            axios.post("/master/classification/sort", {classifications: this.classifications}).then(res => {
                this.reload();
            }).catch(err => {
                Alert.err(err);
            });
        },
        // ドラッグenter
        dragenter(item) {
            // 最後にホバーしたIDを記録
            let tmp = item.order_number
            item.order_number = this.drag.order_number
            this.drag.order_number = tmp
            return
        },
    },
    computed: {
        ordered() {
            return _.orderBy(this.classifications, "order_number");
        }
    },
    mounted() {
        this.classificationModal = new bootstrap.Modal(document.getElementById("classification-modal"));
        this.reload()
    },
}
</script>
